define("linced-enrolments/initializers/oauth2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(/* application */
  ) {
    // application.inject('route', 'foo', 'service:foo');
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});