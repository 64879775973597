define("linced-enrolments/helpers/possible-grades", ["exports", "@linc-technologies/linced-common/helpers/possible-grades"], function (_exports, _possibleGrades) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _possibleGrades.default;
    }
  });
  Object.defineProperty(_exports, "possibleGrades", {
    enumerable: true,
    get: function get() {
      return _possibleGrades.possibleGrades;
    }
  });
});